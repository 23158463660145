<template>
  <el-tabs type="border-card" v-model="tabName" @tab-change="tabChange">
    <el-tab-pane label="销售报表" name="selldata">
      <el-row>
        <el-col :span="24">
          <el-form :inline="true" @submit.prevent>
            <el-form-item label="公司" label-width="80px">
              <el-tree-select ref="companys" v-model="companyId" :data="companys" clearable
                @change="departmentId = ''; companyChange();" check-strictly :render-after-expand="false"
                style="width:220px" />
            </el-form-item>

            <el-form-item label="部门" prop="departmentId" label-width="60px">
              <el-tree-select ref="departments" v-model="departmentId" :data="departments" clearable check-strictly
                @change="fetchs" :render-after-expand="false" style="min-width:150px" />
            </el-form-item>

            <el-form-item label="时间" label-width="60px">
              <el-date-picker v-model="dateRange" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"
                unlink-panels @change="fetchs()" :shortcuts="$util.getShortCuts()" style="max-width:210px;">
              </el-date-picker>
            </el-form-item>

            <el-form-item label="" label-width="0">
              <el-button type="primary" icon="Search" @click="fetchs">搜索</el-button>
              <el-button type="info" icon="Pointer" @click="redirect('order')"
                title="点击查询统计报表的原始订单明细数据">订单明细</el-button>
              <el-button type="info" icon="Tickets" @click="redirect('bills')" title="点击查询统计报表的费用明细数据">费用明细</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <el-scrollbar>
        <div class="statistic-card">
          <el-statistic :value="$util.fmtMoney(totalData.orderCount || 0)">
            <template #title>
              <div style="display: inline-flex; align-items: center">
                订单总数
                <el-tooltip effect="dark" content="时间周期内的销售订单总数" placement="top">
                  <el-icon style="margin-left: 4px" :size="12">
                    <Warning />
                  </el-icon>
                </el-tooltip>
              </div>
            </template>
          </el-statistic>

          <div class="statistic-footer">
            <div class="footer-item">
              退单数：{{ $util.fmtMoney(totalData.returnQty || 0) }}
            </div>
          </div>
        </div>
        <div class="statistic-card">
          <el-statistic :value="$util.fmtMoney(totalData.paymentPrice || 0)">
            <template #title>
              <div style="display: inline-flex; align-items: center">
                应回款金额
                <el-tooltip effect="dark" content="时间周期内的商品销售对应的回款金额" placement="top">
                  <el-icon style="margin-left: 4px" :size="12">
                    <Warning />
                  </el-icon>
                </el-tooltip>
              </div>
            </template>
          </el-statistic>

          <div class="statistic-footer">
            <div class="footer-item">
              销售额：￥{{ $util.fmtMoney(totalData.sellingPrice || 0) }}
            </div>
          </div>
        </div>

        <div class="statistic-card">
          <el-tooltip effect="dark" content="时间周期内部供应链发货数以及第三方代发数" placement="top">
            <el-statistic :value="$util.fmtMoney(totalData.sendOrderCount || 0)">
              <template #title>
                <div style="display: inline-flex; align-items: center">
                  自发货单数
                  <el-icon style="margin-left: 4px" :size="12">
                    <Warning />
                  </el-icon>
                </div>
              </template>
            </el-statistic>
          </el-tooltip>
          <div class="statistic-footer">
            <div class="footer-item">
              <el-tooltip effect="dark" content="未发货或者第三方代发的单数" placement="top">
                未发数：{{ $util.fmtMoney(totalData.noSendOrderCount || 0) }}
              </el-tooltip>
            </div>
          </div>
        </div>

        <div class="statistic-card">
          <el-tooltip effect="dark" content="时间周期订单的 应回款金额-货品成本" placement="top">
            <el-statistic :value="$util.fmtMoney((totalData.paybackPrice || 0) - (totalData.productPrice || 0))">
              <template #title>
                <div style="display: inline-flex; align-items: center">
                  产品毛利额
                  <el-icon style="margin-left: 4px" :size="12">
                    <Warning />
                  </el-icon>
                </div>
              </template>
            </el-statistic>
          </el-tooltip>
          <div class="statistic-footer">
            <div class="footer-item">
              产品毛利率
              {{ totalData.paybackPrice > 0 ? ((((totalData.paybackPrice || 0) - (totalData.productPrice || 0)) /
                totalData.paybackPrice * 100).toFixed(2) + '%') : '' }}
            </div>
          </div>
        </div>

        <div class="statistic-card">
          <el-tooltip effect="dark" content="时间周期已经发货订单的利润（应回款金额-货品成本-综合税-快递费-辅料费）" placement="top">
            <el-statistic :value="$util.fmtMoney(totalData.profitPrice || 0)">
              <template #title>
                <div style="display: inline-flex; align-items: center">
                  销售利润额
                  <el-icon style="margin-left: 4px" :size="12">
                    <Warning />
                  </el-icon>
                </div>
              </template>
            </el-statistic>
          </el-tooltip>
          <div class="statistic-footer">
            <div class="footer-item">
              <!-- 发货对应销售额 ￥{{ $util.fmtMoney(totalData.sendSellPrice || 0) }} -->
              <!-- 综合税 ￥{{ $util.fmtMoney(totalData.taxPrice || 0) }} -->
              销售利润率
              {{ totalData.paybackPrice > 0 ? ((totalData.profitPrice / totalData.paybackPrice * 100).toFixed(2) + '%')
                : '' }}
            </div>
          </div>
        </div>

        <div class="statistic-card">
          <el-tooltip effect="dark" content="时间周期的报销相关支出(排除货款/工费/证书费等相关支出)" placement="top">
            <el-statistic :value="$util.fmtMoney(totalData.remainFee || 0)">
              <template #title>
                <div style="display: inline-flex; align-items: center">
                  报销支出
                  <el-icon style="margin-left: 4px" :size="12">
                    <Warning />
                  </el-icon>
                </div>
              </template>
            </el-statistic>
          </el-tooltip>
          <div class="statistic-footer">
            <div class="footer-item">
              <el-tooltip effect="dark"
                :content="'人力总成本=时间天数' + $util.fmtMoney(totalData.rangeDayNum || 0) + '*每日人力成本' + $util.fmtMoney(totalData.dayLaborCostFee || 0) + '(总薪水成本/发放月数/每月的平均天数30.42)'"
                placement="top">
                人力成本：￥{{ $util.fmtMoney(totalData.laborCostFee || 0) }}
              </el-tooltip>
            </div>
          </div>
        </div>


        <div class="statistic-card">
          <el-tooltip effect="dark" :content="'均摊分为多个类型：1后勤等人力分摊金额' + $util.fmtMoney(totalData.equally1Fee || 0)
            + '；2直接分摊金额' + $util.fmtMoney(totalData.equally2Fee || 0) + '(渠道单独使用的独立设计部和品牌部)；'
            + '3其他的分摊金额；4办公行政固定分摊(月2K*月*人)=' + $util.fmtMoney(totalData.equallyFixedFee || 0)" placement="top">
            <el-statistic :value="$util.fmtMoney(totalData.equallyRemainFee || 0)">
              <template #title>
                <div style="display: inline-flex; align-items: center">
                  均摊合计
                  <el-icon style="margin-left: 4px" :size="12">
                    <Warning />
                  </el-icon>
                </div>
              </template>
            </el-statistic>
          </el-tooltip>
          <div class="statistic-footer">
            其他收入：￥{{ $util.fmtMoney(totalData.otherRemainFee || 0) }}
          </div>
        </div>

        <div class="statistic-card">
          <el-tooltip effect="dark" :content="'利润额=销售利润(回款金额-综合税-货品成本-快递费-辅料费)' + $util.fmtMoney(totalData.profitPrice || 0)
            //+ '-综合税' + $util.fmtMoney(totalData.taxPrice || 0)
            + '-人力成本' + $util.fmtMoney(totalData.laborCostFee || 0)
            + '-报销支出' + $util.fmtMoney(Math.abs(totalData.remainFee) || 0)
            + '-均摊支出' + $util.fmtMoney(Math.abs(totalData.equallyRemainFee) || 0)
            + '+其他收入' + $util.fmtMoney(Math.abs(totalData.otherRemainFee) || 0)
            + '（如未维护订单扣点、回款金额、发货成本等将不纳入统计）'" placement="top">
            <el-statistic :value="$util.fmtMoney(totalData.totalProfitFee || 0)">
              <template #title>
                <div style="display: inline-flex; align-items: center">
                  分摊后净利
                  <el-icon style="margin-left: 4px" :size="12">
                    <Warning />
                  </el-icon>
                </div>
              </template>
            </el-statistic>
          </el-tooltip>
          <div class="statistic-footer">
            剔除各项费用之后的净利润
          </div>
          <!-- <div class="statistic-footer">
              <div class="footer-item">
                <el-tooltip effect="dark" content="剔除报销、人力成本、平台扣点、综合税费及退款后的利润，如未维护扣点或未发货将不纳入统计" placement="top">
                  最终净利润 ￥{{ $util.fmtMoney(totalData.totalProfitFee || 0) }}
                </el-tooltip>
              </div>
            </div> -->
        </div>

        <div class="statistic-card">
          <el-tooltip effect="dark" content="部分发货单因未发货、第三方发货或未维护扣点导致无法统计的数据" placement="top">
            <el-statistic :value="$util.fmtMoney(totalData.noProfitCount || 0)">
              <template #title>
                <div style="display: inline-flex; align-items: center">
                  未维护数
                  <el-icon style="margin-left: 4px" :size="12">
                    <Warning />
                  </el-icon>
                </div>
              </template>
            </el-statistic>
          </el-tooltip>
          <div class="statistic-footer">
            <div class="footer-item">
              -
            </div>
          </div>
        </div>
      </el-scrollbar>
      <div>
        <div class="chartbox" id="deptBox" style="width:80vw;"> </div>
      </div>


      <el-table class="table" ref="sellData" :data="sellData" border size="small" show-summary
        :summary-method="getSellSummaries">
        <el-table-column type="index" label="序" align="center" width="40"> </el-table-column>
        <el-table-column prop="departmentName" label="维度名称" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="orderCount" label="订单数" width="65" align="center">
          <template #default="item">
            <el-link type="primary" size="small" :underline="false" @click="redirect('order')">{{
              $util.fmtMoney(item.row.orderCount || '-') }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="goodsCount" label="货品数" width="65" align="center">
          <template #default="item">{{ $util.fmtMoney(item.row.goodsCount || '-') }}</template>
        </el-table-column>
        <el-table-column prop="sellingPrice" label="销售额" width="70" align="right" show-overflow-tooltip>
          <template #default="item">{{ $util.fmtMoney(item.row.sellingPrice || '-') }}</template>
        </el-table-column>
        <el-table-column prop="returnFee" label="退单额" width="70" align="right" show-overflow-tooltip>
          <template #default="item">{{ $util.fmtMoney(item.row.returnFee || '-') }}</template>
        </el-table-column>
        <!-- <el-table-column prop="paymentPrice" label="付款额" width="70" align="right" show-overflow-tooltip>
          <template #default="item">{{ $util.fmtMoney(item.row.paymentPrice || '-') }}</template>
        </el-table-column> -->
        <el-table-column prop="sendOrderCount" label="发货数" width="65" align="center">
          <template #default="item">{{ $util.fmtMoney(item.row.sendOrderCount || '-') }}</template>
        </el-table-column>
        <el-table-column prop="paybackPrice" label="应回款额" width="70" align="right">
          <template #default="item">{{ $util.fmtMoney(item.row.paybackPrice || '-') }}</template>
        </el-table-column>
        <!-- <el-table-column prop="sendSellPrice" label="发货销售额" width="80" align="right">
          <template #default="item">{{ $util.fmtMoney(item.row.sendSellPrice || '-') }}</template>
        </el-table-column> -->
        <el-table-column prop="productPrice" label="货品成本" width="70" align="right">
          <template #default="item">{{ $util.fmtMoney(item.row.productPrice || '-') }}</template>
        </el-table-column>
        <!-- <el-table-column prop="marginPrice" label="发货利润" width="70" align="right">
          <template #default="item">
            <span title="用户付款金额-货品成本（未算扣点及税费）">
              {{ $util.fmtMoney(item.row.marginPrice || '-') }}
            </span>
          </template>
        </el-table-column> -->
        <el-table-column prop="taxPrice" label="综合税" width="70" align="right">
          <template #default="item">
            <span
              title="根据品类W18K,Y18K,R18K,Y999,FC,JXY为8.5%(无品类)，PT950是13%，S925是14%，其他暂定3% （如果有导入综合税金额则按照导入综合税来算，如果代发单没有款号品类则按8.5%）">
              {{ $util.fmtMoney(item.row.taxPrice || '-') }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="BD产品毛利额" width="95" align="right">
          <template #default="item">
            <span title="自发货BD订单的：应回款金额-货品成本">
              {{ $util.fmtMoney(item.row.bdGrossPrice || 0) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="BD产品毛利率" width="95" align="right">
          <template #default="item">
            <span title="自发货订单：(BD订单的应回款金额-BD订单的货品成本)/BD订单的回款金额">
              {{ item.row.bdPaybackPrice > 0 ? ((item.row.bdGrossPrice / item.row.bdPaybackPrice * 100).toFixed(2) +
                '%') : '' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="产品毛利额" width="85" align="right">
          <template #default="item">
            <span title="自发以及代发订单的应回款金额-货品成本">
              {{ $util.fmtMoney(item.row.grossPrice || 0) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="产品毛利率" width="85" align="right">
          <template #default="item">
            <span title="自发以及代发订单的(应回款金额-货品成本)/回款金额">
              {{ item.row.paybackPrice > 0 ? ((item.row.grossPrice / item.row.paybackPrice * 100).toFixed(2) + '%') : ''}}
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="profitPrice" label="销售利润额" width="85" align="right">
          <template #default="item">
            <span title="销售利润额 = 应回款金额-综合税-货品综合成本-快递费-辅料费">
              {{ $util.fmtMoney(item.row.profitPrice || '-') }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="销售利润率" width="85" align="right">
          <template #default="item">
            <span title="销售利润额/回款金额">
              {{ item.row.paybackPrice > 0 ? ((item.row.profitPrice / item.row.paybackPrice * 100).toFixed(2) + '%') :
                '' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="lastMonthDate" label="月盈亏回款额" width="95" align="right">
          <template #default="item">
            <span
              :title="'部门盈亏回款金额 = ('+item.row.lastMonthDate+')月总支出金额' +  $util.fmtMoney(item.row.lastMonthExpenseFee || 0) + ' % 销售利润率'">
              {{ $util.fmtMoney((item.row.lastMonthExpenseFee/(item.row.profitPrice / item.row.paybackPrice) || '-')) }}
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="remainFee" label="报销支出" width="80" align="right">
          <template #default="item">{{ $util.fmtMoney(item.row.remainFee || '-') }}</template>
        </el-table-column>
        <!-- <el-table-column prop="monthSpan" label="发放的月数" width="55" align="right"> </el-table-column> -->
        <!-- <el-table-column prop="employeeNum" label="月均人数" width="70" align="right">
          <template #default="item">
            <span title="可能会有离职情况出现，所以此处取月的平均人数即部门的月平均人数=总薪水发放次数/月数">
              {{ $util.fmtMoney(item.row.employeeNum || '-') }}
            </span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column prop="salaryNum" label="发放人次" width="70" align="right">
          <template #default="item">
            <span
              :title="'薪水的发放月数' + $util.fmtMoney(item.row.monthSpan || '') + ' (' + $util.fmtMoney(item.row.salaryNum || ')') + '人次)'">
              {{ $util.fmtMoney(item.row.monthSpan || '') }}
              {{ item.row.salaryNum ? ('(' + $util.fmtMoney(item.row.salaryNum || ')') + ')') : '' }}
            </span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column prop="employeeMonthCostFee" label="月人均成本" sortable width="110" align="right"> </el-table-column> -->
        <el-table-column prop="lastSalaryNum" label="上月人数" width="65" align="right">
          <template #default="item">
            <span :title="'最近一个月：' + (item.row.lastEmployeeNames || '-')">
              {{ item.row.lastSalaryNum }}(查看)
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="laborCostFee" label="人力成本" width="70" align="right">
          <template #default="item">
            <span
              :title="'每日部门人力成本' + $util.fmtMoney(item.row.dayLaborCostFee || '-') + '*实际周期天数' + (item.row.rangeDayNum || '-')">
              {{ $util.fmtMoney(item.row.laborCostFee || '-') }}
            </span>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="totalMarginFee" label="扣点前净利" width="80" align="right">
          <template #default="item">
            <span title="净利润额=货品利润(未扣除扣点)-综合税-人力成本-报销收支-均摊收支（未计算退单）">{{
              $util.fmtMoney(item.row.totalMarginFee || '-') }}
            </span>
          </template>
        </el-table-column> -->
        <el-table-column prop="distProfitFee" label="分摊前净利" width="80" align="right">
          <template #default="item">
            <span title="分摊前净利即订单的销售利润额-渠道人员薪资成本等">
              {{ $util.fmtMoney(item.row.distProfitFee || '-') }}
            </span>
          </template>
        </el-table-column>


        <el-table-column prop="equallyFixedFee" label="行政分摊" width="70" align="right">
          <template #default="item">
            <span title="办公行政分摊2000*人*月(房租水电)">
              {{ $util.fmtMoney(item.row.equallyFixedFee * -1.0 || '-') }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="equally1Fee" label="后勤分摊" width="70" align="right">
          <template #default="item">
            <span title="每日后勤分摊*天数（后勤工资成本等分摊金额）">
              {{ $util.fmtMoney(item.row.equally1Fee || '-') }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="equally2Fee" label="直接分摊" width="70" align="right">
          <template #default="item">
            <span title="每日直接分摊*天数（渠道单独使用的独立设计部和品牌部对应分摊）">
              {{ $util.fmtMoney(item.row.equally2Fee || '-') }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="equallyRemainFee" label="均摊总计" width="75" align="right">
          <template #default="item">{{ $util.fmtMoney(item.row.equallyRemainFee || '-') }}</template>
        </el-table-column>
        <el-table-column prop="otherRemainFee" label="其他收入" width="75" align="right">
          <template #default="item">
            <span title="直接录入的利润，不与订单关联的纯利">
              {{ $util.fmtMoney(item.row.otherRemainFee || '-') }}
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="totalProfitFee" label="分摊后净利" width="80" align="right">
          <template #default="item">
            <span title="净利润额=销售利润-人力成本-报销-均摊">{{ $util.fmtMoney(item.row.totalProfitFee || '-') }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="noProfitCount" label="未统计数" width="80" align="right">
          <template #default="item">
            <div title="未统计到利润的订单数包括代发订单等（未维护扣点金额订单的数量或者无订单成本金额的订单数量）">
              {{ $util.fmtMoney(item.row.noProfitCount || '') }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="资金账户" name="balance" class="horizbox" v-if="0">
      <el-form :inline="true" @submit.prevent>
        <el-form-item label="关键词" label-width="80px">
          <el-input v-model="keyword" placeholder="关键词搜索" @keyup.enter="fetchs"> </el-input>
        </el-form-item>
        <el-form-item label="" label-width="0">
          <el-button type="primary" icon="Search" @click="fetchs">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-table class="table" ref="bankAnalyse" :data="bankAnalyse" border size="small" height="420" show-summary
        :summary-method="getBankSummaries">
        <el-table-column type="index" label="序号" align="center" width="50"> </el-table-column>
        <el-table-column label="别名" width="135">
          <template #default="scope">
            <el-link type="primary" size="small" :underline="false"
              @click="$router.push('fundflowlog?cardAlias=' + scope.row.cardAlias)">{{ scope.row.cardAlias
              }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="cardNo" label="账户号" width="130" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="cardName" label="账户名称" width="150" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="bankAbbr" label="银行编号" width="65"> </el-table-column>
        <el-table-column prop="bankName" label="银行名称" sortable width="90" show-overflow-tooltip> </el-table-column>
        <el-table-column label="类型" width="50">
          <template #default="scope">
            <el-link type="primary" v-if="scope.row.bankType == 'Corporate'" size="small" disabled>对公</el-link>
            <el-link type="success" v-if="scope.row.bankType == 'Personal'" size="small" disabled>对私</el-link>
          </template>
        </el-table-column>

        <el-table-column prop="balanceFee" label="最新余额" sortable width="110" align="right"> </el-table-column>
        <el-table-column prop="revenueTotalFee" label="收入总额" sortable width="110" align="right"> </el-table-column>
        <el-table-column prop="expenseTotalFee" label="支出总额" sortable width="110" align="right">
          <template #default="scope">
            <span style="color:red;" v-if="scope.row.expenseTotalFee">({{ scope.row.expenseTotalFee }})</span>
          </template>
        </el-table-column>
        <el-table-column prop="tradeCount" label="交易笔数" width="80" align="center"> </el-table-column>
        <el-table-column prop="lastDate" label="更新时间" sortable width="140"> </el-table-column>
        <el-table-column prop="uploadDate" label="上传时间" sortable width="140"> </el-table-column>
      </el-table>

      <!-- <el-tabs v-model="balName">
        <el-tab-pane label="账户余额" name="balfee" class="horizbox">
          <template #label>
            <span>账户余额</span>
          </template>
          
        </el-tab-pane>
        <el-tab-pane label="资金曲线" name="balchart" class="horizbox">
          <el-form :inline="true" @submit.prevent>
            <el-form-item label="统计周期(开发中)" label-width="130px">

            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs> -->
    </el-tab-pane>
    <el-tab-pane label="收支明细" name="billdata" v-if="0">
      <el-form :inline="true" @submit.prevent>
        <el-form-item label="关键词" label-width="80px">
          <el-input v-model="billData.querys.keyword" placeholder="关键词搜索" @keyup.enter="fetchs"> </el-input>
        </el-form-item>
        <el-form-item label="" label-width="0">
          <el-button type="primary" icon="Search" @click="fetchs">搜索</el-button>
        </el-form-item>
      </el-form>

      <static-table class="table" :pager="billData.pager" :columns="billData.querys.columns" :currHandler="currHandler"
        :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler" :seleHandler="seleHandler">
        <template v-slot:billCode="scope">
          <el-link type="primary" size="small" :underline="false"
            @click="$router.push('/workflow/flowForm?id=' + scope.row.id)">{{ scope.row.billCode }}</el-link>
        </template>
        <template v-slot:status="scope">
          <span style="color:blue;" v-if="scope.row.status == 1">已匹配银行流水</span>
          <span style="color:red;" v-if="scope.row.status == -1">无流水</span>
          <span style="color:gray;" v-else>未匹配到流水</span>
        </template>
      </static-table>
      <!-- <el-table class="table" ref="billAnalyse" :data="billAnalyse" border size="small" height="420" show-summary
          :summary-method="getSummaries">
          <el-table-column type="index" label="序号" align="center" width="50"> </el-table-column>
          <el-table-column label="单据号" width="135">
            <template #default="scope">
              <el-link type="primary" size="small" :underline="false"
                @click="$router.push('/workflow/flowForm?id=' + scope.row.id)">{{ scope.row.billCode }}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="billDate" label="账单时间" sortable width="140"> </el-table-column>
          <el-table-column prop="billName" label="用途" width="130" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="formName" label="申请单" width="150" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="revenueFee" label="收入金额" width="65"> </el-table-column>
          <el-table-column prop="expenseFee" label="支出金额" width="65"> </el-table-column>
          <el-table-column label="状态" width="100">
            <template #default="scope">
              <el-link type="success" v-if="scope.row.status == 1" size="small" disabled>查看银行流水</el-link>
              <el-link type="primary" v-if="scope.row.status == -1" size="small" disabled>无流水</el-link>
              <el-link type="danger" v-else size="small" disabled>未匹配到流水</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="createDate" label="生成时间" sortable width="140"> </el-table-column>
        </el-table> -->
    </el-tab-pane>
    <el-tab-pane label="分析报表" name="sellchart">
      <el-row>
        <el-col :span="24">
          <el-form :inline="true" @submit.prevent>
            <el-form-item label="公司" label-width="80px">
              <el-tree-select ref="companys" v-model="companyId" :data="companys" clearable
                @change="departmentId = ''; companyChange();" check-strictly :render-after-expand="false"
                style="width:220px" />
            </el-form-item>

            <el-form-item label="部门" prop="departmentId" label-width="60px">
              <el-tree-select ref="departments" v-model="departmentId" :data="departments" clearable check-strictly
                @change="fetchs" :render-after-expand="false" style="min-width:150px" />
            </el-form-item>

            <el-form-item label="时间" label-width="60px">
              <el-date-picker v-model="dateRange" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"
                unlink-panels @change="fetchs()" :shortcuts="$util.getShortCuts()" style="max-width:210px;">
              </el-date-picker>
            </el-form-item>

            <el-form-item label="" label-width="0">
              <el-button type="primary" icon="Search" @click="fetchs">搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="18" :offset="6">
          <el-form-item label="统计维度" label-width="150px" style="margin: 20px 0 0 0;">
            <el-select v-model="sellChartGroupType" size="default" style="width:150px;" @change="fetchs()">
              <el-option label="按照部门统计" value="department" />
              <el-option label="按照渠道统计" value="platform" />
            </el-select>

            <el-select v-model="sellChartDataType" size="default" style="width:150px; margin-left:30px"
              @change="fetchs()">
              <el-option label="销售利润额" value="profitPrice" />
              <el-option label="销售利润率" value="profitRate" />
              <el-option label="产品毛利额" value="grossPrice" />
              <el-option label="产品毛利率" value="grossRate" />
              <el-option label="回款金额" value="paybackPrice" />
              <el-option label="订单销售额" value="paymentPrice" />
              <el-option label="发货成本" value="productPrice" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>


      <div>
        <div class="chartbox" id="sellChart" style="width:80vw;"> </div>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import * as echarts from 'echarts';
import request from '@/utils/request'

import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
import { bankAnalyse, fetchBillLog } from "@/api/fund";
export default {
  name: "fundReport",
  components: { StaticDialog, StaticTable },
  data() {
    return {
      tabName: "selldata",
      //balName: 'balfee',
      keyword: '',
      companyId: '',
      companys: [],
      departmentId: '',
      departments: [],

      bankAnalyse: [],
      sellData: [],
      totalData: {},
      dateRange: [],
      billData: {
        querys: {
          keyword: "",
          filters: [],
          columns: [
            // { type: "selection", width: "55" },
            { type: "index", label: "序号" },
            { type: "template", prop: "billCode", label: "单据号", width: "165", sortable: true, searchable: true, showOverflowTooltip: true },
            { prop: "billDate", label: "账单时间", width: "145", sortable: true, searchable: true, showOverflowTooltip: true },
            { prop: "billName", label: "用途", width: "150", sortable: true, searchable: true, showOverflowTooltip: true, },
            { prop: "formName", label: "申请单", width: "130", sortable: true, searchable: true, showOverflowTooltip: true, },

            { prop: "revenueFee", label: "收入金额", width: "90", sortable: true, },
            { prop: "expenseFee", label: "支出金额", width: "90", sortable: true, },

            { type: "template", prop: "status", label: "状态", width: "120", sortable: true, showOverflowTooltip: true, },

            { prop: "createDate", label: "导入时间", width: "140", sortable: true, },
            // { type: "toolbar", label: "操作", width: "80" },
          ],
        },
        pager: {
          sidx: "billDate", //默认的排序字段
          sord: "descending",
        },
      },
      sellChartGroupType: 'department',
      sellChartDataType: 'paymentPrice'
    };
  },
  mounted() {
  },
  methods: {
    tabChange() {
      this.fetchs()
    },
    fetchs() {
      if (this.tabName == 'balance') {
        bankAnalyse(this.keyword).then((res) => {
          this.bankAnalyse = res.data;
        });
      }
      else if (this.tabName == 'billdata') {
        this.billData.querys.filters = [];
        let { data, records, total, ...search } = {
          ...this.billData.pager,
          ...this.billData.querys,
        };

        fetchBillLog(search).then((res) => {
          this.billData.pager = { ...res.data };
        })
      }
      else if (this.tabName == 'selldata') {
        var params = {
          companyId: this.companyId,
          departmentId: this.departmentId,
        };
        if (this.dateRange && this.dateRange.length > 1) {
          params.startDate = this.$util.fmtDate(this.dateRange[0], "yyyy-MM-dd");
          params.endDate = this.$util.fmtDate(this.dateRange[1], "yyyy-MM-dd") + " 23:59:59";
        }
        request({
          url: '/FundReport/SellAnalyse',
          method: 'get',
          params: params
        }).then((res) => {
          this.sellData = res.data.sellData;
          this.totalData = res.data.totalData;

          this.mainEchartInit();
        });
      } else if (this.tabName == 'sellchart') {
        var params = {
          companyId: this.companyId,
          departmentId: this.departmentId,
        };
        if (this.dateRange && this.dateRange.length > 1) {
          params.startDate = this.$util.fmtDate(this.dateRange[0], "yyyy-MM-dd");
          params.endDate = this.$util.fmtDate(this.dateRange[1], "yyyy-MM-dd") + " 23:59:59";
        };
        request({
          url: '/FundReport/SellChartDataByOrderMonth?groupType=' + this.sellChartGroupType,
          method: 'get',
          params: params
        }).then((res) => {
          let labelMonths = this.$util.getYearAndMonth(this.$util.fmtDate(this.dateRange[0], "yyyy-MM"), this.$util.fmtDate(this.dateRange[1], "yyyy-MM"));

          let labelNames = [];
          res.data.forEach(resData => {
            if (labelNames.filter(item => item == resData.labelName).length == 0)
              labelNames.push(resData.labelName);
          });
          console.log(labelNames);

          let resultDatas = [];
          labelNames.forEach(labelName => {
            let channelData = [];
            labelMonths.forEach(labelMonth => {
              let monthData = res.data.find(item => item.labelName == labelName && item.labelMonth == labelMonth);
              if (!monthData) monthData = { labelName: labelName, labelMonth: labelMonth };

              if (this.sellChartDataType == 'grossRate') {
                let grossRate = monthData.paybackPrice > 0 ? (monthData.paybackPrice - monthData.productPrice) / monthData.paybackPrice : 0;
                channelData.push(grossRate);
              }else if (this.sellChartDataType == 'profitRate') {
                let profitRate = monthData.profitPrice > 0 ? monthData.profitPrice / monthData.profitPrice : 0;
                channelData.push(profitRate);
              }
              else
                channelData.push(monthData[this.sellChartDataType] || 0);

              // if (this.sellChartDataType == 'profitPrice')
              //   channelData.push(channelMonthData.profitPrice || 0);
              // else if (this.sellChartDataType == 'grossPrice')
              //   channelData.push(channelMonthData.grossPrice || 0);
              // else if (this.sellChartDataType == 'grossPrice')
              //   channelData.push(channelMonthData.grossPrice || 0);
              // else if (this.sellChartDataType == 'paybackPrice')
              //   channelData.push(channelMonthData.paybackPrice || 0);
              // else if (this.sellChartDataType == 'paymentPrice')
              //   channelData.push(channelMonthData.paymentPrice || 0);
            });
            resultDatas.push({
              name: labelName,
              type: 'line',
              // stack: 'Total',
              // markPoint: {
              //   data: [
              //     { type: 'max', name: '最大' },
              //     //{ type: 'min', name: '最小' }
              //   ]
              // },
              // markLine: {
              //   data: [{ type: 'average', name: '平均' }]
              // },
              data: channelData
            });
          });
          console.log(resultDatas);
          this.sellEchartInit(labelMonths, labelNames, resultDatas);
        });
      }
    },
    getBankSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 2 || index === 11) return;

        if (index === 0) {//只找第一列放合计
          sums[index] = '合计';
          return;
        }
        //把对应一列中的之全部取出，放到一个数组中
        const values = data.map(item => Number(item[column.property]));
        //如果el-table-column中没有prop属性，这里的column.property是undefined，所以判断一下values数组是否为空
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            //console.log(prev, curr);
            //将values中的每个值转换为number类型
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if (index == 12) {
            sums[index] = '';
          }
          else if (index === 10) { //交易笔数
            sums[index] = this.$util.fmtMoney(sums[index] || 0);
          } else {//金额
            sums[index] = '￥' + this.$util.fmtMoney(sums[index] || 0);
          }

          //sums[index] += '元';
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    getSellSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {//只找第一列放合计
          sums[index] = '合计';
        }
        //把对应一列中的之全部取出，放到一个数组中
        const values = data.map(item => Number(item[column.property]));
        //如果el-table-column中没有prop属性，这里的column.property是undefined，所以判断一下values数组是否为空
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            //将values中的每个值转换为number类型
            const value = Number(curr);
            if (!isNaN(value)) {

              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = this.$util.fmtMoney(sums[index] || 0);
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },


    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      if (this.tabName == 'billdata') {
        this.billData.pager.page = page;
      }
      this.fetchs();
    },
    sizeHandler(rows) {
      if (this.tabName == 'billdata') {
        this.billData.pager.rows = rows;
      }
      this.fetchs();
    },
    sortHandler(order) {
      if (this.tabName == 'billdata') {
        this.billData.pager.sidx = order.prop;
        this.billData.pager.sord = order.order;
      }
      this.fetchs();
    },
    seleHandler(rows) {
      this.selects = rows;
    },


    companyChange() {
      var that = this;
      if (!this.companyId) {
        this.departmentId = '';
        that.fetchs();
        return;
      }

      this.$kaung.departments(this.companyId).then((res) => {
        that.departments = res;
        that.fetchs();
      });
    },

    mainEchartInit() {
      var app = {};


      var chartDom = document.getElementById('deptBox');
      var myChart = echarts.getInstanceByDom(chartDom);
      if (myChart == null) myChart = echarts.init(chartDom);
      var option;
      const posList = [
        'left',
        'right',
        'top',
        'bottom',
        'inside',
        'insideTop',
        'insideLeft',
        'insideRight',
        'insideBottom',
        'insideTopLeft',
        'insideTopRight',
        'insideBottomLeft',
        'insideBottomRight'
      ];
      app.configParameters = {
        rotate: {
          min: -90,
          max: 90
        },
        align: {
          options: {
            left: 'left',
            center: 'center',
            right: 'right'
          }
        },
        verticalAlign: {
          options: {
            top: 'top',
            middle: 'middle',
            bottom: 'bottom'
          }
        },
        position: {
          options: posList.reduce(function (map, pos) {
            map[pos] = pos;
            return map;
          }, {})
        },
        distance: {
          min: 0,
          max: 100
        }
      };
      app.config = {
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideBottom',
        distance: 15,
        onChange: function () {
          const labelOption = {
            rotate: app.config.rotate,
            align: app.config.align,
            verticalAlign: app.config.verticalAlign,
            position: app.config.position,
            distance: app.config.distance
          };
          myChart.setOption({
            series: [
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              }
            ]
          });
        }
      };
      const labelOption = {
        show: true,
        position: app.config.position,
        distance: app.config.distance,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        rotate: app.config.rotate,
        formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
          name: {}
        }
      };



      let xAxisData = [];
      this.sellData.forEach(element => {
        xAxisData.push(element.departmentName);
      });

      let legends = ['总销售额', '已发货的销售额', '发货成本', '销售利润'];
      let seriesData = [];
      for (let x = 0; x < legends.length; x++) {
        let legendItemData = [[], [], [], [], []];
        for (let y = 0; y < xAxisData.length; y++) {
          let rowsData = this.sellData.filter(z => z.departmentName == xAxisData[y]);

          let rowData = {};
          if (rowsData.length > 0) rowData = rowsData[0];

          legendItemData[0].push(rowData.sellingPrice || 0); //销售总额
          legendItemData[1].push(rowData.sendSellPrice || 0); //销售中发货对应的销售额
          legendItemData[2].push(rowData.productPrice || 0);  //发货成本
          //legendItemData[3].push(rowData.marginPrice || 0);   //扣点前毛利
          legendItemData[3].push(rowData.profitPrice || 0);   //销售利润
        }
        seriesData.push({
          name: legends[x],
          type: 'bar',
          barGap: 0,
          label: labelOption,
          emphasis: {
            focus: 'series'
          },
          data: legendItemData[x]
        });
      }

      option = {
        title: {
          text: '渠道销售及支出综合报表',
          textAlign: 'left',
          left: '1%',
          //transform: 'translate(-50%,-50%)'
        },
        tooltip: {  //鼠标悬浮提示
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          right: '0%',
          data: legends
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar', 'stack'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: xAxisData //['一部', '二部', '三部', '五部', '六部', '七部']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: seriesData
        // [
        //   {
        //     name: '总销售额',
        //     type: 'bar',
        //     barGap: 0,
        //     label: labelOption,
        //     emphasis: {
        //       focus: 'series'
        //     },
        //     data: [320, 660, 301, 334, 390, 120]
        //   },
        //   {
        //     name: '已发货的销售额',
        //     type: 'bar',
        //     label: labelOption,
        //     emphasis: {
        //       focus: 'series'
        //     },
        //     data: [220, 182, 191, 234, 290, 0]
        //   },
        //   {
        //     name: '发货成本',
        //     type: 'bar',
        //     label: labelOption,
        //     emphasis: {
        //       focus: 'series'
        //     },
        //     data: [150, 232, 201, 154, 190, 0]
        //   },
        //   {
        //     name: '发货毛利',
        //     type: 'bar',
        //     label: labelOption,
        //     emphasis: {
        //       focus: 'series'
        //     },
        //     data: [98, 77, 101, 99, 40, 120]
        //   }
        // ]
      };

      myChart.setOption(option, true);
    },

    redirect(bizType, item) {
      item = item || this;
      if (bizType == 'order')
        this.$router.push("/fund/ordersell?dateRange=" + encodeURIComponent(JSON.stringify(this.dateRange))
          + "&companyId=" + this.companyId + "&departmentId=" + this.departmentId);
      else if (bizType == 'bills')
        this.$router.push("/fund/fundbilllog?dateRange=" + encodeURIComponent(JSON.stringify(this.dateRange))
          + "&companyId=" + this.companyId + "&departmentId=" + this.departmentId);
    },

    sellEchartInit(labelMonths, labelNames, resultDatas) {
      let option = {
        title: {
          left: '3%',
          text: '部门销售订单趋势图'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          right: '4%',
          data: labelNames
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar', 'stack'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: labelMonths
        },
        yAxis: {
          type: 'value'
        },
        series: resultDatas
        // [
        //   {
        //     name: 'Email',
        //     type: 'line',
        //     stack: 'Total',
        //     data: [120, 132, 101, 134, 90, 230, 210]
        //   }
        // ]
      };

      var chartDom = document.getElementById('sellChart');
      var sellChart = echarts.getInstanceByDom(chartDom);
      if (sellChart == null) sellChart = echarts.init(chartDom);
      sellChart.setOption(option, true);
    }
  },
  mounted: function () {
    const date = new Date();
    let start = new Date(date.getFullYear(), 0, 1);
    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);
    this.dateRange = [start, end]; //this.$util.getCurrentDate()

    this.$kaung.companys().then((res) => {
      this.companys = res;
      //this.companyId = this.$util.getLocalstorage('companyIdStr') || '';
      this.companyChange();
    });
  },
  watch: {
    // companyId: function (newValue, oldValue) {
    //   if (newValue && newValue != oldValue)
    //     this.$util.setLocalstorage('companyIdStr', newValue);
    //   else
    //     this.$util.setLocalstorage('companyIdStr', '');
    // },
    // departmentId: function (newValue, oldValue) {
    //   if (newValue != oldValue) {
    //     this.$util.setLocalstorage('departmentIdStr', newValue);
    //   }
    // }
  }
};
</script>
<style scoped>
/* :deep .el-tabs__content {
  padding: 0 8px
} */


.el-statistic {
  --el-statistic-content-font-size: 28px;
}

.statistic-card {
  padding: 20px;
  border-radius: 4px;
  background-color: #dddddd;
  color: #000;
  margin: 5px 5px;
  max-width: 250px;
  display: inline-block;
}


.statistic-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  margin-top: 8px;
}

.statistic-footer .footer-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chartbox {
  height: 420px;
  margin-top: 30px;
}
</style>